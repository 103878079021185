import React, { useRef } from 'react';
import type { FC } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Text,
} from '@chakra-ui/core';

import { EinsteinButton } from 'components/_lib';
import type { MessageDialogState } from './types';

// MessageDialog component prop types
interface MessageDialogProps extends MessageDialogState {
  isOpen: boolean;
  onClose: VoidFunction;
}

/**
 * Pop-up information modal
 */
const MessageDialog: FC<MessageDialogProps> = ({
  action,
  isOpen,
  message,
  onClose,
  title,
}: MessageDialogProps) => {
  const cancelRef = useRef<HTMLElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
      preserveScrollBarGap
      size={['full', 'md']}
    >
      <AlertDialogOverlay />
      <AlertDialogContent rounded={[0, 8]} color="teal.500" p={2}>
        <AlertDialogHeader
          fontSize="xl"
          fontWeight="bold"
          fontFamily="heading"
          textTransform="uppercase"
        >
          {title}
        </AlertDialogHeader>
        <AlertDialogBody>
          <Text>{message}</Text>
        </AlertDialogBody>

        <AlertDialogFooter>
          <EinsteinButton ref={cancelRef} onClick={onClose} variant="outline">
            Back
          </EinsteinButton>
          {action && (
            <EinsteinButton
              onClick={() => {
                action();
                onClose();
              }}
              ml={3}
            >
              Update Information
            </EinsteinButton>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MessageDialog;
