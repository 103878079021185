import React, { memo } from 'react';
import type { FC, ReactElement } from 'react';

import { Flex, Icon, IconButton } from '@chakra-ui/core';
import type { ButtonProps, IconButtonProps } from '@chakra-ui/core';

import { OnboardingSteps } from './types';

// StepButton component prop types
type StepButtonProps = Partial<ButtonProps & IconButtonProps> & {
  step: number;
};

/**
 * Button to navigate on-boarding:
 *   -> Renders a backtrack button for steps 0-4
 *   -> Renders a close button for step 5
 */
const StepButton: FC<StepButtonProps> = ({
  step,
  ...props
}: StepButtonProps) => (
  <Flex
    alignItems="center"
    w="100%"
    justifyContent={step !== 5 ? 'flex-start' : 'flex-end'}
  >
    <IconButton
      aria-label={
        step === OnboardingSteps.welcome || step === OnboardingSteps.thankYou
          ? 'return to website'
          : 'back up one step'
      }
      icon={(): ReactElement => (
        <Icon
          name={step !== OnboardingSteps.thankYou ? 'chevron-left' : 'close'}
          size={step !== OnboardingSteps.thankYou ? '2rem' : '1rem'}
        />
      )}
      variantColor="teal"
      variant="ghost"
      my={2}
      ml={step !== OnboardingSteps.thankYou ? '-.75rem' : undefined}
      {...props}
    />
  </Flex>
);

export default memo(StepButton);
