import React from 'react';
import type { FC } from 'react';

import { withSEO } from 'components/_hoc';
import SummerOnboarding from 'components/SummerOnboarding';

/**
 * Summercamp onboarding sign-up page
 */
const SummerSignupPage: FC = () => <SummerOnboarding />;

export default withSEO(SummerSignupPage, {
  title: 'Signup • Spanish Immersive Summer',
  description:
    'This summer, experience the most immersive way to learn Spanish safely from home. First 50 signups get 40% off!',
  pathname: '/signup/summer',
  image: `${process.env.WEB_PLATFORM_URL}/summer-camp-2020-1200x630.png`,
});
