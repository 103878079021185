import React from 'react';
import type { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Box } from '@chakra-ui/core';
import Img from 'gatsby-image';

/**
 * Oculus VR product stock photo
 */
const OculusImage: FC<{}> = () => {
  const data = useStaticQuery(graphql`
    query {
      oculusImage: file(
        relativePath: { eq: "images/oculus-go-facing-left.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Box
      position="absolute"
      right={[4, 8, 16, 32]}
      opacity={[0.3, null, 1]}
      zIndex={-1}
      size="sm"
    >
      <Img fluid={data.oculusImage.childImageSharp.fluid} alt="Oculus Go VR" />
    </Box>
  );
};

export default OculusImage;
