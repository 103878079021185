import React, { useState } from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';

import { useDisclosure } from '@chakra-ui/core';

import API from 'api';
import { Section, SectionTitle } from 'components/_lib';
import MessageModal from './MessageDialog';
import ProgressStepper from './ProgressStepper';
import StepButton from './StepButton';
import ParentOnboardingSteps from './Steps';
import { OnboardingSteps } from './types';
import type {
  AddressState,
  DiscountState,
  MessageDialogState,
  ProfileState,
  StepsContainerProps,
} from './types';

// Onboarding step titles
const TITLES = [
  'Welcome!',
  'Your information',
  'VR headset shipping',
  'Summary',
  'Thank you!',
];

/**
 * Wraps parent on-boarding process
 */
const SummerOnboardingContainer: FC = () => {
  // Current on-boarding step
  const [step, setStep] = useState<OnboardingSteps>(OnboardingSteps.welcome);

  // New account information state
  const [profile, setProfile] = useState<ProfileState>({
    student: '',
    fullName: '',
    preferredName: '',
    primaryEmail: '',
    phone: '',
  });

  // Oculus VR already owned boolean
  const [needsOculus, setNeedsOculus] = useState<boolean>(true);

  // Mailing information state
  const [address, setAddress] = useState<AddressState>({
    address1: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  // Discount code state
  const [discount, setDiscount] = useState<DiscountState>({
    code: '',
    percentOff: 0,
    description: '',
    isInvalid: false,
  });

  // Content and action for MessageModal
  const [messageInfo, setMessageInfo] = useState<MessageDialogState>({
    title: '',
    message: '',
    action: null,
  });

  // Submission in progress boolean
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // MessageModal state
  const { isOpen, onClose, onOpen } = useDisclosure();

  /**
   * Back up to the previous step in the onboarding process
   */
  const handleBack: VoidFunction = () => {
    switch (step) {
      case OnboardingSteps.welcome:
        window.location.href = 'https://www.einsteinstudios.io/spanishclasses';
        break;

      case OnboardingSteps.account:
        setStep(OnboardingSteps.welcome);
        break;

      case OnboardingSteps.shipping:
        setStep(OnboardingSteps.account);
        break;

      case OnboardingSteps.summary:
        setStep(
          !needsOculus ? OnboardingSteps.account : OnboardingSteps.shipping
        );
        break;

      default:
        break;
    }
  };

  /**
   * Advance to the next step in the onboarding process
   */
  const handleNext: VoidFunction = () => {
    switch (step) {
      case OnboardingSteps.welcome:
        setStep(OnboardingSteps.account);
        break;

      case OnboardingSteps.account:
        setStep(
          needsOculus ? OnboardingSteps.shipping : OnboardingSteps.summary
        );
        break;

      case OnboardingSteps.shipping:
        setStep(OnboardingSteps.summary);
        break;

      case OnboardingSteps.summary:
        setStep(OnboardingSteps.thankYou);
        break;

      case OnboardingSteps.thankYou:
        window.location.href = 'https://www.einsteinstudios.io/spanishclasses';
        break;

      default:
        break;
    }
  };

  /**
   * Submit on-boarding registration to API
   *
   * @param token - Stripe payment source.
   * @param note - Optional parent note to submit w/ application
   */
  const handleSubmit: StepsContainerProps['handleSubmit'] = async (
    stripe,
    card,
    note
  ) => {
    setIsSubmitting(true);
    const { student, ...parent } = profile;

    try {
      const { token, error } = await stripe.createToken(card, {
        name: profile.fullName,
        currency: 'usd',
      });

      if (error || !token?.id) {
        return setIsSubmitting(false);
      }

      await API.post('/summer/signup', {
        ...parent,
        address: needsOculus ? address : undefined,
        student,
        note,
        token: token.id,
        discountCode:
          discount.code.length > 0 &&
          discount.percentOff > 0 &&
          !discount.isInvalid
            ? discount.code
            : undefined,
      });

      setIsSubmitting(false);
      handleNext();
      return ReactGA.event({
        category: 'Summer Camp 2020',
        action: 'Completed Entire Process',
      });
    } catch (error) {
      const isDuplicateParentError =
        error.response.data.message === 'Duplicate parent';
      const discountMaxRedeemedError =
        error.response.data.message ===
        'Summer discount code is no longer valid';

      if (discountMaxRedeemedError) {
        setDiscount({
          ...discount,
          percentOff: 0,
          description: 'Code has reached maximum redemptions',
          isInvalid: false,
        });
      }

      setMessageInfo({
        title: error.response.status === 409 ? 'Conflict' : 'An Error Occurred',
        message: isDuplicateParentError
          ? 'The email you entered already exists with an account. Please log in to your existing account, or enter a new email address.'
          : discountMaxRedeemedError
          ? 'Sorry! The discount code you entered has just reached its maximum number of redemptions.'
          : 'Apologies, we could not process your application at this time. Please reach out to us or try again later.',
        action: isDuplicateParentError
          ? (): void => {
              setProfile({ ...profile, primaryEmail: '' });
              setStep(OnboardingSteps.account);
            }
          : null,
      });
      onOpen();
      return setIsSubmitting(false);
    }
  };

  return (
    <>
      <Section
        d="flex"
        px={[4, 6, 8, 10]}
        py={[3, 4, 5, 6]}
        bg="rgba(216, 216, 216, 0.2)"
        rounded="lg"
        border="1px solid rgb(151, 151, 151, 0.2)"
        boxShadow="lg"
      >
        <StepButton
          step={step}
          onClick={step !== OnboardingSteps.thankYou ? handleBack : handleNext}
        />
        <SectionTitle whiteSpace="nowrap" overflow="auto">
          {TITLES[step]}
        </SectionTitle>
        <ProgressStepper step={step} />
        <ParentOnboardingSteps
          step={step}
          setStep={setStep}
          handleNext={handleNext}
          profile={profile}
          setProfile={setProfile}
          needsOculus={needsOculus}
          setNeedsOculus={setNeedsOculus}
          address={address}
          setAddress={setAddress}
          discount={discount}
          setDiscount={setDiscount}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
        />
      </Section>
      <MessageModal isOpen={isOpen} onClose={onClose} {...messageInfo} />
    </>
  );
};

export default SummerOnboardingContainer;
