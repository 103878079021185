import React from 'react';
import type { FC } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { OnboardingSteps } from '../types';
import type { StepsContainerProps } from '../types';
import ProfileStep from './ProfileStep';
import ShippingStep from './ShippingStep';
import SummaryStep from './SummaryStep';
import ThankYouStep from './ThankYouStep';
import WelcomeStep from './WelcomeStep';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_API_KEY!);

/**
 * Controls summer on-boarding steps
 */
const StepsContainer: FC<StepsContainerProps> = ({
  step,
  setStep,
  handleNext,
  discount,
  setDiscount,
  profile,
  setProfile,
  needsOculus,
  setNeedsOculus,
  address,
  setAddress,
  isSubmitting,
  handleSubmit,
}: StepsContainerProps) => {
  switch (step) {
    case OnboardingSteps.welcome:
      return <WelcomeStep handleNext={handleNext} />;

    case OnboardingSteps.account:
      return (
        <ProfileStep
          handleNext={handleNext}
          profile={profile}
          setProfile={setProfile}
          needsOculus={needsOculus}
          setNeedsOculus={setNeedsOculus}
        />
      );

    case OnboardingSteps.shipping:
      return (
        <ShippingStep
          handleNext={handleNext}
          address={address}
          setAddress={setAddress}
          setNeedsOculus={setNeedsOculus}
        />
      );

    case OnboardingSteps.summary:
      return (
        <Elements stripe={stripePromise}>
          <SummaryStep
            address={address}
            profile={profile}
            setStep={setStep}
            discount={discount}
            setDiscount={setDiscount}
            needsOculus={needsOculus}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
          />
        </Elements>
      );

    default:
      return <ThankYouStep />;
  }
};

export default StepsContainer;
