import React, { memo } from 'react';
import type { FC } from 'react';

import { Button } from '@chakra-ui/core';
import type { ButtonProps } from '@chakra-ui/core';

/**
 * Button to advance to next step
 */
const NextButton: FC<ButtonProps> = ({
  children,
  variantColor = 'orange',
  ...rest
}: ButtonProps) => (
  <Button
    aria-label="advance to next step"
    variantColor={variantColor}
    size="lg"
    textTransform="uppercase"
    borderRadius="8px"
    fontFamily="heading"
    {...rest}
  >
    {children}
  </Button>
);

export default memo(NextButton);
