import React, { useState } from 'react';
import type { FC } from 'react';

import { Box } from '@chakra-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { OnboardingSection, OnboardingSectionBody } from 'components/_lib';
import type { StepsContainerProps } from '../../types';
import NextButton from '../NextButton';
import NotesForm from './NotesForm';
import PaymentForm from './PaymentForm';
import ShippingInformation from './ShippingInformation';

// SummaryStepContainer component prop types
type SummaryStepContainerProps = Pick<
  StepsContainerProps,
  | 'address'
  | 'profile'
  | 'setStep'
  | 'discount'
  | 'setDiscount'
  | 'needsOculus'
  | 'isSubmitting'
  | 'handleSubmit'
>;

/**
 * Wraps on-boarding summary, step 4
 */
const SummaryStepContainer: FC<SummaryStepContainerProps> = ({
  address,
  profile,
  needsOculus,
  discount,
  setDiscount,
  isSubmitting,
  handleSubmit,
}: SummaryStepContainerProps) => {
  const stripe = useStripe();
  const elements = useElements();

  // Optional note form input
  const [note, setNote] = useState<string>('');

  // Proceed to payment step
  const handleClick: VoidFunction = async () => {
    const card = elements?.getElement(CardElement);
    if (stripe && card) {
      handleSubmit(stripe, card, note.length === 0 ? undefined : note);
    }
  };

  const divider = (
    <Box w="100%" borderBottom="1px solid" borderColor="gray.500" />
  );

  return (
    <OnboardingSection>
      <OnboardingSectionBody>
        <Box w="100%">
          <ShippingInformation
            needsOculus={needsOculus}
            address={address}
            profile={profile}
          />
        </Box>
        {divider}
        <Box w="100%">
          <PaymentForm discount={discount} setDiscount={setDiscount} />
        </Box>
        {divider}
        <Box w="100%">
          <NotesForm
            profile={profile}
            note={note}
            handleNoteChange={({ target }) => {
              setNote(target.value);
            }}
          />
        </Box>
        <NextButton
          onClick={handleClick}
          isLoading={isSubmitting}
          loadingText="Submitting"
        >
          Submit
        </NextButton>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default SummaryStepContainer;
