import React from 'react';
import type { FC } from 'react';

import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/core';

import ValidationCircleSvg from 'assets/svgs/validation-circle.svg';
import {
  ButtonChakraLink,
  Emoji,
  OnboardingSection,
  OnboardingSectionBody,
} from 'components/_lib';

/**
 * Thank you and next steps note, end of on-boarding
 */
const ThankYouStep: FC = () => (
  <OnboardingSection>
    <OnboardingSectionBody textAlign="center">
      <Flex w="6rem" h="6rem">
        <ValidationCircleSvg />
      </Flex>
      <Heading size="md">We have received your application!</Heading>
      <Stack spacing={8} align="center">
        <Text maxW={['100%', '375px']}>
          Wow, thank you! We appreciate your signing up with us, and are{' '}
          <Box as="span" d="inline" color="orange.500">
            super excited
          </Box>{' '}
          to get to know your student this summer.
        </Text>
        <Text maxW={['100%', '375px']}>
          We will be{' '}
          <Box as="span" d="inline" color="orange.500">
            reaching out by phone ASAP
          </Box>{' '}
          to confirm your account and schedule your 8 Spanish classes.{' '}
          <Emoji symbol="😁" label="grinning-face-with-smiling-eyes" />
        </Text>
      </Stack>

      <ButtonChakraLink
        aria-label="return to main website"
        size="lg"
        variant="outline"
        href={process.env.LANDING_PAGE_URL}
      >
        Return to website
      </ButtonChakraLink>
    </OnboardingSectionBody>
  </OnboardingSection>
);

export default ThankYouStep;
