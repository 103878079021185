import React from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';

import { Box } from '@chakra-ui/core';

import { OnboardingSection, OnboardingSectionTitle } from 'components/_lib';
import type { StepsContainerProps } from '../../types';
import ShippingForm from './ShippingForm';

// ShippingStepContainer component prop types
type ShippingStepContainerProps = Pick<
  StepsContainerProps,
  'handleNext' | 'address' | 'setAddress' | 'setNeedsOculus'
>;

/**
 * Wraps Oculus VR shipping form, step 3
 */
const ShippingStepContainer: FC<ShippingStepContainerProps> = ({
  handleNext,
  address,
  setAddress,
  setNeedsOculus
}: ShippingStepContainerProps) => {
  // Skip shipping
  const handleSkip: VoidFunction = () => {
    setNeedsOculus(false);
    handleNext();
    return ReactGA.event({
      category: 'Onboarding',
      action: 'Oculus Go VR Shipping Skipped'
    });
  };

  // Proceed to next step
  const handleSubmit: VoidFunction = () => {
    setNeedsOculus(true);
    handleNext();
    return ReactGA.event({
      category: 'Onboarding',
      action: 'Oculus Go VR Shipping Information Entered'
    });
  };

  return (
    <OnboardingSection textAlign="center">
      <OnboardingSectionTitle w={['85%', '100%']} textAlign="center">
        Finally, let&apos;s ship you a brand new Oculus Go VR headset.
      </OnboardingSectionTitle>
      <Box w="100%">
        <ShippingForm
          address={address}
          setAddress={setAddress}
          handleSubmit={handleSubmit}
          handleSkip={handleSkip}
        />
      </Box>
    </OnboardingSection>
  );
};

export default ShippingStepContainer;
