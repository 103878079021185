import type { Dispatch, SetStateAction } from 'react';
import type { Stripe, StripeCardElement } from '@stripe/stripe-js';

// Enumeration of onboarding steps
export enum OnboardingSteps {
  welcome,
  account,
  shipping,
  summary,
  thankYou,
}

/**
 * New account information
 */

export interface ProfileState {
  [key: string]: string;
  student: string;
  fullName: string;
  preferredName: string;
  primaryEmail: string;
  phone: string;
}

/**
 * Mailing information
 */

export interface AddressState {
  [key: string]: string | undefined;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

/**
 * Discount code state
 */

export interface DiscountState {
  code: string;
  percentOff: number;
  description: string;
  isInvalid: boolean;
}

/**
 * Content and action for MessageDialog
 */

export interface MessageDialogState {
  title: string;
  message: string;
  action: VoidFunction | null;
}

/**
 * StepsContainer component prop types
 */
export interface StepsContainerProps {
  step: OnboardingSteps;
  setStep: Dispatch<SetStateAction<OnboardingSteps>>;
  handleNext: VoidFunction;
  profile: ProfileState;
  setProfile: Dispatch<SetStateAction<ProfileState>>;
  needsOculus: boolean;
  setNeedsOculus: Dispatch<SetStateAction<boolean>>;
  address: AddressState;
  setAddress: Dispatch<SetStateAction<AddressState>>;
  discount: DiscountState;
  setDiscount: Dispatch<SetStateAction<DiscountState>>;
  isSubmitting: boolean;
  handleSubmit: (
    stripe: Stripe,
    card: StripeCardElement,
    note?: string
  ) => void;
}
