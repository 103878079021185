import React from 'react';
import type { FC } from 'react';

import { Flex, Text, List, ListIcon, ListItem } from '@chakra-ui/core';
import { FaSun } from 'react-icons/fa';

import {
  OnboardingSection,
  OnboardingSectionBody,
  OnboardingSectionTitle,
} from 'components/_lib';
import type { StepsContainerProps } from '../../types';

// ShippingInformation component prop types
type ShippingInformationProps = Pick<
  StepsContainerProps,
  'address' | 'profile' | 'needsOculus'
>;

/**
 *  Oculus VR shipping information
 */
const ShippingInformation: FC<ShippingInformationProps> = ({
  address,
  needsOculus,
  profile,
}: ShippingInformationProps) => {
  return (
    <OnboardingSection>
      <OnboardingSectionTitle textAlign="left">Summary:</OnboardingSectionTitle>

      <OnboardingSectionBody>
        <Flex
          flexDirection={['column', null, 'row']}
          alignItems="center"
          justifyContent={['center', null, 'space-evenly']}
          w="100%"
        >
          <List spacing={3}>
            <ListItem>
              <ListIcon icon={FaSun} color="orange.500" />
              Eight 25-minute Spanish classes with a live tutor
            </ListItem>
            <ListItem>
              <ListIcon icon={FaSun} color="orange.500" />
              We will reach out to you to schedule your 8 classes
            </ListItem>
            {needsOculus && (
              <ListItem>
                <ListIcon icon={FaSun} color="orange.500" />
                We will lend you an Oculus Go VR headset
              </ListItem>
            )}
          </List>

          <Flex alignItems="center" justifyContent="center">
            <Text textAlign="left" mt={[8, null, 0]}>
              {profile.fullName}
              <br />
              {address.address1}
              <br />
              {address.address2 && (
                <>
                  {address.address2}
                  <br />
                </>
              )}
              {`${address.city}, ${address.state} ${address.zip}`}
              <br />
              {address.country}
              <br />
              {profile.phone}
              <br />
              {profile.email}
            </Text>
          </Flex>
        </Flex>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default ShippingInformation;
