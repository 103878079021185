import React from 'react';
import type { FC } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Icon,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/core';
import {
  FaAddressCard,
  FaEnvelope,
  FaUser,
  FaUserAstronaut,
} from 'react-icons/fa';

import API from 'api';
import { EinsteinInput } from 'components/_lib';
import type { ProfileState, StepsContainerProps } from '../../types';
import NextButton from '../NextButton';
import NeedsOculusRadio from './NeedsOculusRadio';

const EMAIL_REGEX = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

// ProfileForm component prop types
interface ProfileFormProps
  extends Pick<
    StepsContainerProps,
    'profile' | 'setProfile' | 'needsOculus' | 'setNeedsOculus'
  > {
  handleSubmit: VoidFunction;
}

/**
 * Form to input profile info and indicate if VR is already owned
 */
const ProfileForm: FC<ProfileFormProps> = ({
  profile,
  setProfile,
  needsOculus,
  setNeedsOculus,
  handleSubmit,
}: ProfileFormProps) => {
  // Form state and handlers
  const formik = useFormik<ProfileState>({
    initialValues: profile,
    validate: (values) => {
      const errors: Partial<ProfileState> = {};

      if (!values.student) {
        errors.student = 'Required';
      }
      if (!values.fullName) {
        errors.fullName = 'Required';
      }
      if (!values.preferredName) {
        errors.preferredName = 'Required';
      }
      if (!values.primaryEmail) {
        errors.primaryEmail = 'Required';
      }
      if (!values.phone) {
        errors.phone = 'Required';
      }
      if (!values.primaryEmail) {
        errors.primaryEmail = 'Required';
      } else if (!EMAIL_REGEX.test(values.primaryEmail)) {
        errors.primaryEmail = 'Invalid email address';
      }

      return errors;
    },
    onSubmit: async (values, { setErrors }) => {
      // Verify that email and username do not already exist
      const response = await API.get('/waitlist/validate/email', {
        params: { email: values.primaryEmail },
      });

      if (response.data === true) {
        return setErrors({ primaryEmail: 'Email already exists.' });
      }

      setProfile(values);
      return handleSubmit();
    },
  });

  const studentError =
    formik.touched.student === true && formik.errors.student !== undefined;
  const fullNameError =
    formik.touched.fullName === true && formik.errors.fullName !== undefined;
  const preferredNameError =
    formik.touched.preferredName === true &&
    formik.errors.preferredName !== undefined;
  const primaryEmailError =
    formik.touched.primaryEmail === true &&
    formik.errors.primaryEmail !== undefined;
  const phoneError =
    formik.touched.phone === true && formik.errors.phone !== undefined;

  return (
    <Stack spacing={8} align="center" w={['100%', '80%', 'auto']}>
      <Stack spacing={4} w="100%">
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={8} align="center" w="360px" maxW="100%">
            <FormControl isInvalid={studentError} w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Box
                    as={FaUserAstronaut}
                    size="1em"
                    color="teal.500"
                    opacity={0.36}
                  />
                </InputLeftElement>
                <EinsteinInput
                  id="student"
                  name="student"
                  isRequired
                  placeholder="Student username"
                  pl="2.5rem"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.student}
                />
              </InputGroup>
              <FormHelperText
                id="student-helper-text"
                color="teal.500"
                opacity={0.36}
                textAlign="left"
              >
                Your student&apos;s first name or nickname.
              </FormHelperText>
              <FormErrorMessage>{formik.errors.student}</FormErrorMessage>
            </FormControl>

            <Box
              borderBottom="2px"
              borderBottomColor="orange.500"
              w="60%"
              mx="auto"
            />

            <FormControl isInvalid={fullNameError} w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Box as={FaUser} size="1em" color="teal.500" opacity={0.36} />
                </InputLeftElement>
                <EinsteinInput
                  id="fullName"
                  name="fullName"
                  isRequired
                  placeholder="Your full name"
                  pl="2.5rem"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                />
              </InputGroup>
              <FormErrorMessage>{formik.errors.fullName}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={preferredNameError} w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Box
                    as={FaAddressCard}
                    size="1em"
                    color="teal.500"
                    opacity={0.36}
                  />
                </InputLeftElement>
                <EinsteinInput
                  id="preferredName"
                  name="preferredName"
                  placeholder="Your preferred name"
                  pl="2.5rem"
                  isRequired
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.preferredName}
                />
              </InputGroup>
              <FormHelperText
                id="preferredName-helper-text"
                color="teal.500"
                opacity={0.36}
                textAlign="left"
              >
                How you&apos;d prefer us to address you.
              </FormHelperText>
              <FormErrorMessage>{formik.errors.preferredName}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={primaryEmailError} w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Box
                    as={FaEnvelope}
                    size="1em"
                    color="teal.500"
                    opacity={0.36}
                  />
                </InputLeftElement>
                <EinsteinInput
                  id="primaryEmail"
                  name="primaryEmail"
                  isRequired
                  type="email"
                  pl="2.5rem"
                  placeholder="Your email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.primaryEmail}
                />
              </InputGroup>
              <FormErrorMessage>{formik.errors.primaryEmail}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={phoneError} w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Icon name="phone" color="teal.500" opacity={0.36} />
                </InputLeftElement>
                <EinsteinInput
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  pl="2.5rem"
                  isRequired
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </InputGroup>
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>

            <Box>
              <NeedsOculusRadio
                needsOculus={needsOculus}
                setNeedsOculus={setNeedsOculus}
              />
            </Box>

            <NextButton
              type="submit"
              onClick={formik.handleSubmit}
              isLoading={formik.isSubmitting}
              loadingText="Verifying..."
            >
              Next
            </NextButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
};

export default ProfileForm;
