import React from 'react';
import type { FC } from 'react';

import { Button, Flex } from '@chakra-ui/core';

import NextButton from '../NextButton';

// ShippingForm component prop types
interface ProceedButtonsProps {
  handleSubmit: VoidFunction;
  handleSkip: VoidFunction;
  verifying: boolean;
}

/**
 * Buttons to either submit shipping information or skip Oculus shipping
 */
const ProceedButtons: FC<ProceedButtonsProps> = ({
  handleSubmit,
  handleSkip,
  verifying,
}: ProceedButtonsProps) => (
  <Flex
    w="100%"
    align="center"
    justify="center"
    flexDirection={['column', null, 'row']}
  >
    <NextButton
      onClick={handleSubmit}
      isLoading={verifying}
      loadingText="Verifying"
      d="flex"
      alignSelf="center"
      alignItems="center"
      margin={[0, null, '0 auto']}
      position={['relative', null, 'absolute']}
    >
      Next
    </NextButton>
    <Button
      variant="ghost"
      rightIcon="chevron-right"
      ml={[0, null, 'auto']}
      fontWeight="normal"
      onClick={handleSkip}
      my={[4, null, 0]}
    >
      I already own one
    </Button>
  </Flex>
);

export default ProceedButtons;
