import React from 'react';
import type { ChangeEventHandler, FC } from 'react';
import styled from '@emotion/styled';

import { Stack, Text, Textarea } from '@chakra-ui/core';

import colors from 'theme/colors';
import type { StepsContainerProps } from '../../types';

// Custom styled Chakra-UI Textarea
const StyledTextarea = styled(Textarea)`
  &::placeholder {
    color: ${colors.teal['500']};
    opacity: 0.36;
  }
`;

// NotesForm component prop types
interface NotesFormProps extends Pick<StepsContainerProps, 'profile'> {
  note: string;
  handleNoteChange: ChangeEventHandler<HTMLInputElement>;
}

/**
 * Form inputs for parent to write optional note & referred by
 */
const NoteForm: FC<NotesFormProps> = ({
  profile: { student },
  note,
  handleNoteChange,
}: NotesFormProps) => (
  <Stack
    spacing={8}
    w="100%"
    align="center"
    justify="center"
    textAlign="center"
  >
    <Stack spacing={4} w="400px" maxW="100%" align="center" justify="center">
      <Text>
        Anything else you would like to share about <strong>{student}</strong>?
      </Text>
      <StyledTextarea
        focusBorderColor="orange.500"
        borderColor="gray.500"
        _hover={{ borderColor: 'gray.700' }}
        borderRadius={8}
        value={note}
        onChange={handleNoteChange}
        placeholder="Optional comment..."
      />
    </Stack>
  </Stack>
);

export default NoteForm;
