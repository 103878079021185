import React, { useState } from 'react';
import type { ChangeEvent, FC } from 'react';

import {
  Box,
  Button,
  Stack,
  Flex,
  Text,
  Link,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  FormHelperText,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/core';
import { CardElement } from '@stripe/react-stripe-js';
import { FaTag } from 'react-icons/fa';

import {
  OnboardingSection,
  OnboardingSectionBody,
  EinsteinInput,
} from 'components/_lib';
import API from 'api';
import PoweredByStripeSvg from 'assets/svgs/powered-by-stripe.svg';
import colors from 'theme/colors';
import type { StepsContainerProps } from '../../types';

const BASE_PRICE = 199;
const DISCOUNT_PRICE = 119;

// PaymentForm component prop types
type PaymentFormProps = Pick<StepsContainerProps, 'discount' | 'setDiscount'>;

/**
 * Payment input
 */
const PaymentForm: FC<PaymentFormProps> = ({
  discount,
  setDiscount,
}: PaymentFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = async (): Promise<void> => {
    if (discount.code.toLowerCase() !== 'immersion') {
      return setDiscount({
        code: discount.code,
        percentOff: 0,
        description: 'Invalid code',
        isInvalid: true,
      });
    }

    try {
      setIsLoading(true);
      const { data } = await API.get('/summer/discount', {
        params: { code: discount.code },
      });

      return setDiscount({
        code: discount.code,
        percentOff: data.redemptions > 0 ? data.percentOff : 0,
        description:
          data.redemptions > 0
            ? '40% off for first 50 signups'
            : 'Code has reached maximum redemptions',
        isInvalid: false,
      });
    } catch {
      return setDiscount({
        code: discount.code,
        percentOff: 0,
        description: 'Could not validate code at this time',
        isInvalid: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OnboardingSection>
      <OnboardingSectionBody>
        <Flex
          flexDirection={['column', null, 'row']}
          alignItems="center"
          justifyContent={['center', null, 'space-evenly']}
          w="100%"
        >
          <Stack
            w="400px"
            maxW="100%"
            align="start"
            justify="center"
            textAlign="left"
            spacing={6}
          >
            <Stack>
              <Text>Total</Text>
              <Text
                fontWeight="bold"
                fontSize="1.5rem"
                color={discount.percentOff > 0 ? 'green.500' : 'teal.500'}
              >
                $
                {(discount.percentOff > 0 && !discount.isInvalid
                  ? DISCOUNT_PRICE
                  : BASE_PRICE
                ).toFixed(2)}
              </Text>
            </Stack>
            <Stack w="100%" align="start" justify="center">
              <Text>Discount Code:</Text>
              <FormControl isInvalid={discount.isInvalid} w="100%">
                <InputGroup w="100%">
                  <InputLeftElement>
                    <Box
                      as={FaTag}
                      size="1em"
                      color={discount.percentOff > 0 ? 'green.500' : 'teal.500'}
                      opacity={0.36}
                    />
                  </InputLeftElement>
                  <EinsteinInput
                    pl="2.5rem"
                    pr="4.5rem"
                    value={discount.code}
                    onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                      setDiscount({ ...discount, code: target.value });
                    }}
                  />
                  <InputRightElement width="4.5rem" mr={1}>
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                      isLoading={isLoading}
                    >
                      Enter
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {discount.description !== '' && !discount.isInvalid && (
                  <FormHelperText
                    color={discount.percentOff > 0 ? 'green.500' : 'teal.500'}
                  >
                    {discount.description}
                  </FormHelperText>
                )}
                <FormErrorMessage>{discount.description}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>

          <Stack
            spacing={4}
            mt={[8, null, 0]}
            ml={[0, null, 8]}
            w="400px"
            maxW="100%"
            align="start"
            justify="center"
          >
            <Stack spacing={8} w="100%" align="center">
              <Stack
                spacing={4}
                w="100%"
                backgroundColor="#f6f9fc"
                boxShadow="lg"
                textAlign="center"
                align="center"
                justify="center"
                rounded={8}
                p={4}
              >
                <Text>Pay with card:</Text>
                <Box w="100%" bg="white" p={4} borderRadius={4}>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          color: colors.teal['500'],
                          fontFamily: '"Segoe UI", sans-serif',
                          '::placeholder': {
                            color: '#a6bcc1',
                          },
                        },
                      },
                    }}
                  />
                </Box>
                <Flex align="center" justify="center">
                  <Link isExternal href="https://stripe.com">
                    <PoweredByStripeSvg />
                  </Link>
                </Flex>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default PaymentForm;
