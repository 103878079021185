import React from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';
import { graphql, useStaticQuery } from 'gatsby';

import { Box, Link, Text } from '@chakra-ui/core';
import Img from 'gatsby-image';

import { OnboardingSection, OnboardingSectionBody } from 'components/_lib';
import type { StepsContainerProps } from '../types';
import NextButton from './NextButton';

// WelcomeStep component prop types
type WelcomeStepProps = Pick<StepsContainerProps, 'handleNext'>;

/**
 * On-boarding welcome and get started, step 0
 */
const WelcomeStep: FC<WelcomeStepProps> = ({
  handleNext,
}: WelcomeStepProps) => {
  const data = useStaticQuery(graphql`
    query {
      deliveryTruck: file(relativePath: { eq: "images/delivery-truck.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  // Proceed to next step
  const handleClick: VoidFunction = () => {
    handleNext();
    ReactGA.event({
      category: 'Summer Camp 2020',
      action: 'Got Started',
    });
  };

  return (
    <OnboardingSection>
      <OnboardingSectionBody w="500px" maxW="100%" textAlign="center">
        <Box w={['275px', '100%']} maxW="100%">
          ¡Holá! Welcome to Einstein Studios&apos;{' '}
          <Text d="inline-flex" color="orange.500">
            Spanish Immersive Summer
          </Text>
          !
          <br />
          <br />
          To get started, let&apos;s{' '}
          <Text d="inline-flex" color="orange.500">
            create your account
          </Text>{' '}
          and prepare shipping for a{' '}
          <Text d="inline-flex" color="orange.500">
            virtual reality headset
          </Text>
          .
        </Box>
        <Box w="100%">
          <Img
            fluid={data.deliveryTruck.childImageSharp.fluid}
            alt="Delivery truck with packages falling out back"
          />
        </Box>
        <NextButton onClick={handleClick} w="100%">
          Get Started
        </NextButton>
        <Text maxW="400px">
          By clicking &quot;Get Started&quot;, you agree to the{' '}
          <Link color="orange.500" href="/terms-of-service.pdf" isExternal>
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link color="orange.500" href="/privacy-policy.pdf" isExternal>
            Privacy Policy
          </Link>
          .
        </Text>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default WelcomeStep;
