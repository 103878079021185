import React from 'react';
import type { FC, ReactElement } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/core';
import {
  FaCity,
  FaGlobeAmericas,
  FaHome,
  FaMap,
  FaMapMarkerAlt,
} from 'react-icons/fa';

import {
  EinsteinInput,
  EinsteinSelect,
  OnboardingSectionBody,
} from 'components/_lib';

import type { AddressState, StepsContainerProps } from '../../types';
import COUNTRIES from './countries';
import OculusImage from './OculusImage';
import ProceedButtons from './ProceedButtons';

// ShippingForm component prop types
interface ShippingFormProps
  extends Pick<StepsContainerProps, 'address' | 'setAddress'> {
  handleSubmit: VoidFunction;
  handleSkip: VoidFunction;
}

/**
 * Shipping information input form
 */
const ShippingForm: FC<ShippingFormProps> = ({
  address,
  setAddress,
  handleSubmit,
  handleSkip,
}: ShippingFormProps) => {
  // Form state and handlers
  const formik = useFormik<AddressState>({
    initialValues: {
      address1: address.address1,
      city: address.city,
      state: address.state,
      country: address.country,
      zip: address.zip,
    },
    validate: (values) => {
      const errors: Partial<AddressState> = {};

      if (!values.address1) errors.address1 = 'Required';
      if (!values.city) errors.city = 'Required';
      if (!values.state) errors.state = 'Required';
      if (!values.country) errors.country = 'Required';
      if (!values.zip) errors.zip = 'Required';

      return errors;
    },
    onSubmit: async (values) => {
      setAddress({
        ...values,
        address2: values.address2 !== '' ? values.address2 : undefined,
        phone: values.phone !== '' ? values.phone : undefined,
      });
      return handleSubmit();
    },
  });

  const address1Error =
    formik.touched.address1 === true && formik.errors.address1 !== undefined;
  const cityError =
    formik.touched.city === true && formik.errors.city !== undefined;
  const stateError =
    formik.touched.state === true && formik.errors.state !== undefined;
  const countryError =
    formik.touched.country === true && formik.errors.country !== undefined;
  const zipError =
    formik.touched.zip === true && formik.errors.zip !== undefined;

  return (
    <form onSubmit={formik.handleSubmit}>
      <OnboardingSectionBody align={['center', null, 'flex-start']}>
        <Stack spacing={8} w={['100%', '80%', '55%']} ml={{ xl: 16 }}>
          <Stack spacing={8} align="center">
            <FormControl isInvalid={address1Error} w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Box as={FaHome} size="1em" color="teal.500" opacity={0.36} />
                </InputLeftElement>
                <EinsteinInput
                  id="address1"
                  name="address1"
                  isRequired
                  placeholder="Address 1"
                  pl="2.5rem"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address1}
                />
              </InputGroup>
              <FormHelperText
                id="address1-helper-text"
                color="teal.500"
                opacity={0.36}
                textAlign="left"
              >
                Street address, P.O. box, company name, c/o
              </FormHelperText>
              <FormErrorMessage>{formik.errors.address1}</FormErrorMessage>
            </FormControl>
            <FormControl w="100%">
              <InputGroup>
                <InputLeftElement>
                  <Box as={FaHome} size="1em" color="teal.500" opacity={0.36} />
                </InputLeftElement>
                <EinsteinInput
                  id="address2"
                  name="address2"
                  placeholder="Address 2"
                  pl="2.5rem"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address2}
                />
              </InputGroup>
              <FormHelperText
                id="address2-helper-text"
                color="teal.500"
                opacity={0.36}
                textAlign="left"
              >
                Aptartment, suite, unit, floor, building, etc.
              </FormHelperText>
            </FormControl>
            <Flex flexDirection={['column', null, 'row']} w="100%">
              <FormControl
                isInvalid={cityError}
                w={['100%', null, 'calc(50% - 0.25rem)']}
                mr={[0, null, '0.5rem']}
                mb={['1rem', null, 0]}
              >
                <InputGroup>
                  <InputLeftElement>
                    <Box
                      as={FaCity}
                      size="1em"
                      color="teal.500"
                      opacity={0.36}
                    />
                  </InputLeftElement>
                  <EinsteinInput
                    id="city"
                    name="city"
                    placeholder="City"
                    pl="2.5rem"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.city}
                  />
                </InputGroup>
                <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={stateError}
                w={['100%', null, 'calc(50% - 0.25rem)']}
              >
                <InputGroup>
                  <InputLeftElement>
                    <Box
                      as={FaMap}
                      size="1em"
                      color="teal.500"
                      opacity={0.36}
                    />
                  </InputLeftElement>
                  <EinsteinInput
                    id="state"
                    name="state"
                    placeholder="State/Province/Region"
                    pl="2.5rem"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.state}
                  />
                </InputGroup>
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex flexDirection={['column', null, 'row']} w="100%">
              <FormControl
                isInvalid={zipError}
                w={['100%', null, 'calc(50% - 0.25rem)']}
                mr={[0, null, '0.5rem']}
                mb={['1rem', null, 0]}
              >
                <InputGroup>
                  <InputLeftElement>
                    <Box
                      as={FaMapMarkerAlt}
                      size="1em"
                      color="teal.500"
                      opacity={0.36}
                    />
                  </InputLeftElement>
                  <EinsteinInput
                    id="zip"
                    name="zip"
                    pl="2.5rem"
                    placeholder="ZIP/Postal Code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.zip}
                  />
                </InputGroup>
                <FormErrorMessage>{formik.errors.zip}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={countryError}
                w={['100%', null, 'calc(50% - 0.25rem)']}
              >
                <InputGroup>
                  <InputLeftElement>
                    <Box
                      as={FaGlobeAmericas}
                      size="1em"
                      color="teal.500"
                      opacity={0.36}
                    />
                  </InputLeftElement>
                  <EinsteinSelect
                    id="country"
                    name="country"
                    pl="2.5rem"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    color={
                      formik.values.country === ''
                        ? 'rgba(7, 68, 82, 0.3)'
                        : undefined
                    }
                  >
                    <option value="" disabled hidden>
                      Country
                    </option>
                    {COUNTRIES.map(
                      ({ name, code }): ReactElement => (
                        <option key={code} value={name}>
                          {name}
                        </option>
                      )
                    )}
                  </EinsteinSelect>
                </InputGroup>
                <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
              </FormControl>
            </Flex>
            <OculusImage />
          </Stack>
        </Stack>
      </OnboardingSectionBody>
      <ProceedButtons
        handleSubmit={formik.handleSubmit}
        handleSkip={handleSkip}
        verifying={formik.isSubmitting}
      />
    </form>
  );
};

export default ShippingForm;
